<template>
  <div class="page_container">
    <div v-if="title!='智慧IMOO炼化工厂'">
      <div
        class="banner"
        :style="`background `"
      >
        <div class="page-container">
          <div
            class="back"
            @click="handleBack"
          ><i class="el-icon-arrow-left"></i>返回</div>
          <p class="title">{{title+'解决方案'}}</p>
        </div>
      </div>

      <div
        class="page2 page-container"
        ref="page2"
        v-if="!$store.state.isPhone"
      >
        <div class="page-title">{{`什么是${title}解决方案?`}}</div>
        <p class="sub_title">{{desc}}</p>
        <div class="page-container">
          <div class="list-item">
            <div
              v-for="(item,i) in wt"
              :key="item.step"
              :class="`list-item-pro animate__animated animate__fadeInUp animate__delay-${i+1}s`"
            >
              <div class="item-top">
                <div>
                  <p class="step">{{item.step}}</p>
                  <p class="title">{{item.title}}</p>
                </div>
                <div>
                  <el-image
                    fit="contain"
                    :src="item.icon"
                    class="image"
                  ></el-image>
                </div>
              </div>
              <div class="item-bottom">
                <p>{{item.desc}}</p>
              </div>
              <el-image
                fit="containe"
                :src="require('../../assets/industry/i-page2-bg.png')"
                class="p2_bg"
              ></el-image>
            </div>
          </div>
        </div>
      </div>

      <div
        class="p-page2"
        v-if="$store.state.isPhone"
      >
        <div class="page-title">{{`什么是${title}?`}}</div>
        <p class="sub_title">{{desc}}</p>
        <div
          v-for="(item,i) in wt"
          :key="item.step"
          :class="`list-item-pro animate__animated animate__fadeInUp animate__delay-${i}s`"
        >
          <div class="item-top">
            <div>
              <p class="step">{{item.step}}</p>
              <p class="title">{{item.title}}</p>
            </div>
            <div>
              <el-image
                fit="contain"
                :src="item.icon"
                class="image"
              ></el-image>
            </div>
          </div>
          <div class="item-bottom">
            <p>{{item.desc}}</p>
          </div>
          <el-image
            fit="containe"
            :src="require('../../assets/industry/i-page2-bg.png')"
            class="p2_bg"
          ></el-image>
        </div>
      </div>

      <div
        class="page6 page-grey"
        v-if="!$store.state.isPhone"
      >
        <div class="page-container">
          <div class="page-title">{{`${title}应用功能`}}</div>
          <p class="sub_title">{{desc1}}</p>
          <el-image
            fit="contain"
            :src="yy"
            class="img"
          ></el-image>
        </div>
      </div>

      <div
        class="p-page6 page-grey"
        v-if="$store.state.isPhone"
      >
        <div class="page-title">{{`${title}应用功能`}}</div>
        <p class="sub_title">{{desc1}}</p>
        <el-image
          fit="contain"
          :src="require('../../assets/proInfo/pro-info2.png')"
          class="p-img"
        ></el-image>
      </div>

      <div
        class="page5"
        :style="`${$store.state.isPhone&&'padding:25px 0 0 0;'}`"
      >
        <div class="page-container">
          <div class="page-title">应用场景</div>

          <div class="content">
            <div
              v-for="(item,i) in sc"
              :key="item"
              class="item"
              :style="`background:url(${imgList[i]}) no-repeat;background-size: cover;width:${page5Index==i?100/(sc.length+1)*2:100/(sc.length+1)}%`"
              @mouseenter="page5Index=i"
            >
              <p>{{item}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <sl-imoo
        :title='title'
        :desc='desc'
        :desc1='desc1'
        :wt='wt'
        :sc='sc'
        :imgList='imgList'
        :banner='banner'
      ></sl-imoo>
    </div>
  </div>
</template>

<script>
import SlImoo from './sl-1'
export default {
  name: "detail_in",
  components: {
    SlImoo
  },
  data() {
    return {
      title: localStorage.getItem('CISSLUTIONINFO'),
      desc1: '',
      desc: '',
      data: [],
      wt: [],
      sc: [],
      yy: '',
      imgList: [],
      page5Index: 0,
      banner: '',
      list: [
        {
          title: '智慧园区',
          desc: '以绿色、安全、高效、智能为目标，构建集中管控、安全节能、服务全面配套，企业一站式入驻的综合性智能化园区，通过集中化、智能化管控和精益生产，减少各种物质和能源消耗，减少环境污染，形成科技含量高、经济效益好、资源消耗低、环境污染少的全新发展形态。',
          desc1: '融合智慧园区基础数字化管理和服务；利用大数据、工业（行业）互联网、区块链、人工智能等新一代信息技术，打造产业互联网应用引擎，链接产业全链条、助力企业降本提质增效、赋能园区及企业重塑产业新生态。',
          sc: ['研究院高校', 'CBd商圈', '能源工厂', '数字化小区'],
          wt: [
            { icon: require("../../assets/industry/i-page2-1.png"), step: 'STEP1', title: '顶层全面规划', desc: '基于园区业态研究，以问题痛点为应用导向，形成标准体系和指标量化的顶层规划。' },
            { icon: require("../../assets/industry/i-page2-2.png"), step: 'STEP2', title: '数据智慧应用', desc: '围绕业态业务应用和绿色节能等，形成基于数据智能驱动、高度集成的全周期精细管理、决策和优质服务等智慧应用。' },
            { icon: require("../../assets/industry/i-page2-3.png"), step: 'STEP3', title: '平台架构先进、可信', desc: '基于安全可信、云大物智移等多技术融合的园区智能大脑和数字化平台基础设施；实现运营信息化、数智能化、服务平台化、应用移动化的智慧园区数字化发展目标。' },
          ],
          yy: require("../../assets/solution/park/s-sl-yy.png"),
          banner: require("../../assets/solution/park/s-sl-banner.png"),
          imgList: [require("../../assets/solution/park/s-sl-img-1.png"), require("../../assets/solution/park/s-sl-img-2.png"), require("../../assets/solution/park/s-sl-img-4.png"), require("../../assets/solution/park/s-sl-img-3.png")],
        },
        {
          title: '智能工厂',
          desc: '通过DCS、MES等信息系统的全面建设和集成应用，实现计划调度智能化、能源管理智能化、安全环保智能化、生产操作智能化、IT管控智能化，帮助企业优化资源配置，提高生产稳定性，减少安全环保事故，实现产品质量提升、节能降耗、维稳增效的目标。',
          desc1: '全方位助力石油化工、电子、注塑、机械加工、光伏等业态的数字化转型',
          sc: ['石油、石化工厂', '流程制造业', '热加工行业', '半导体制造'],
          wt: [
            { icon: require("../../assets/industry/i-page2-1.png"), step: 'STEP1', title: '生产车间无纸化', desc: '通过MES系统实现工厂生产车间管理无纸化，实现高效快速的生产管理。' },
            { icon: require("../../assets/industry/i-page2-2.png"), step: 'STEP2', title: '生产进度数字化', desc: '随时掌控生产进度与生产参数，了解车间生产情况。' },
            { icon: require("../../assets/industry/i-page2-3.png"), step: 'STEP3', title: '平台架构先进、可信', desc: '实现信息的高效传递、优化处理过程，实时掌控生产数据。' },
          ],
          yy: require("../../assets/solution/factory/s-sl-yy.png"),
          banner: require("../../assets/solution/factory/s-sl-banner.png"),
          imgList: [require("../../assets/solution/factory/s-sl-img-1.png"), require("../../assets/solution/factory/s-sl-img-2.png"), require("../../assets/solution/factory/s-sl-img-3.png"), require("../../assets/solution/factory/s-sl-img-4.png"),],
        },
        {
          title: '智慧LNG',
          desc: '按照工厂全生命周期管理理念，应用物联网、云计算等先进技术，从智慧工程、数字孪生、指挥运营三个层面，构建智慧化的LNG接收站，解决工程建设、生产运营和安全管控瓶颈，实现预测性的业务管控和数字化的工作体验，全方位推动生产运营模式转变，提升运营效率，降低运营成本和管控风险，打造“智慧LNG接收站”标杆。',
          desc1: '大力推动标准化、模块化、信息化，努力建成“全数字化移交、全智能化运营、全生命周期管理”的智慧LNG接收站。',
          sc: ['天然气行业'],
          wt: [
            { icon: require("../../assets/industry/i-page2-1.png"), step: 'STEP1', title: '智慧工程', desc: '数据采集标准、数字化交付、工程建设过程管理、工程数据采集、竣工资料管理、投产模拟与仿真。' },
            { icon: require("../../assets/industry/i-page2-2.png"), step: 'STEP2', title: '数字孪生', desc: '工程实体建模、工程过程数据展示、生产运行模型搭建、生产运行数据展示。' },
            { icon: require("../../assets/industry/i-page2-3.png"), step: 'STEP3', title: '智慧运营', desc: '智慧巡检、设备全生命周期管理、设备故障智能预测与维修、智能安防、智能生产运行、智慧应急调度智慧。' },
          ],
          yy: require("../../assets/solution/lng/s-sl-yy.png"),
          banner: require("../../assets/solution/lng/s-sl-banner.png"),
          imgList: [require("../../assets/solution/lng/s-sl-img-1.png")],
        },
        {
          title: '智能安全管控',
          desc: '构建企业安全要素辨识（工艺、仪表、电信、电气、设备、配管、安全、信息等专业协同）的数据采集系统，建立以安全状态为核心的企业安全认知的方法与体系，全面、规范、准确的获取安全信息，实现安全信息的采集、存储、分类与融合，建立标准化的企业安全数据库，实现企业安全状态的智能诊断，处置方案的智能匹配及对应急处置合理客观的评价。',
          desc1: '企业安全管理系统,智慧式安全用电管理系统,安全隐患排查系统,多个功能模块,可自定义的数据分析,满足企业内部管理需求,助力企业实现关键人掌控的管理,帮助企业精细化管理..',
          sc: ['污水处理厂', '石化行业', '智慧消防', '安防技术'],
          yy: require("../../assets/solution/com/s-sl-yy.png"),
          banner: require("../../assets/solution/com/s-sl-banner.png"),
          wt: [
            { icon: require("../../assets/industry/i-page2-1.png"), step: 'STEP1', title: '安全预警', desc: '及时预测预警，建立企业安全生产、环保指标数据库，超出监测系统预警上下限值自动报警，指挥中心根据情况实时反馈企业安全环保应急管理部门。' },
            { icon: require("../../assets/industry/i-page2-2.png"), step: 'STEP2', title: '安全监测', desc: '整合企业各种资源，将重大危险源在线监测系统、环境在线监测系统、视频监控系统、安全DCS控制系统及等系统整合为一体化管理平台，发挥资源整合作用。' },
            { icon: require("../../assets/industry/i-page2-3.png"), step: 'STEP3', title: '实时监控', desc: '实时在线监测监控，实现主要危险源数据的采集与集中监控，具体采集数据信息，根据数据采集模块采集、存储的数据进行监测、整理、报警、联动，实现企业24小时安全应急管理。' },
          ],
          imgList: [require("../../assets/solution/com/s-sl-img-1.png"), require("../../assets/solution/com/s-sl-img-3.png"), require("../../assets/solution/com/s-sl-img-4.png"), require("../../assets/solution/com/s-sl-img-2.png"),],
        },
        {
          title: '智能生产管控',
          desc: '通过对企业生产管理、过程控制等信息的处理、分析、优化，建立覆盖企业生产管理与优化业务的综合管控平台，提高对生产过程大量实时数据的监测、分析和预测能力，最终实现生产安全稳定、操作受控运行和运营优化智能的目标，增加高效产品产量，提高产品质量，降低生产消耗和生产经营成本，提高整个炼化业务的运营效率和市场竞争能力。',
          desc1: '',
          sc: ['石油、石化工厂', '流程制造业', '热加工行业', '半导体制造'],
          wt: [
            { icon: require("../../assets/industry/i-page2-1.png"), step: 'STEP1', title: '生产可视化', desc: '进度汇报自动关联生产数据，一键查看生产订单所有执行过程（子单合集），实时跟踪、掌控订单进度，及时调整、安排生产活动，进度一目了然。' },
            { icon: require("../../assets/industry/i-page2-2.png"), step: 'STEP2', title: '生产质量控制', desc: '供生产质检和委外质检，自定义设置质检字段、编号、类型、等级，支持质检单跟进、合格品入库、不合格品返修，降低废品率，为企业节约成本。' },
            { icon: require("../../assets/industry/i-page2-3.png"), step: 'STEP3', title: '生产资源集成', desc: '建立规范、统一、完整的生产数据库，各区域、各系统、各环节数据互联互通，实时掌控生产动态，快速提升协作效率，有效预防生产问题和故障。' },
          ],
          yy: require("../../assets/solution/proc/s-sl-yy.png"),
          banner: require("../../assets/solution/proc/s-sl-banner.png"),
          imgList: [require("../../assets/solution/proc/s-sl-img-1.png"), require("../../assets/solution/proc/s-sl-img-2.png"), require("../../assets/solution/proc/s-sl-img-3.png"), require("../../assets/solution/proc/s-sl-img-4.png")],
        },
        {
          title: '生产优化',
          desc: '石化企业对生产装置的优化正由传统依靠经验的优化向依靠机理建模的模拟优化的方向发展，对生产装置进行机理建模，优化调度异常处置方案、优化生产运行操作参数、发现生产瓶颈、修订工艺卡片，最终提升目的产品收率，降低装置物耗和能耗，提高企业经济效益 。',
          desc1: '',
          sc: ['石油、石化工厂', '流程制造业', '热加工行业', '半导体制造'],
          yy: require("../../assets/solution/op/s-sl-yy.png"),
          banner: require("../../assets/solution/op/s-sl-banner.png"),
          wt: [
            { icon: require("../../assets/industry/i-page2-1.png"), step: 'STEP1', title: '建立基础工况、流程模拟模型', desc: '采集现场数据，分析主要生产瓶颈，根据装置实际情况，搭建严格流程模拟模型。' },
            { icon: require("../../assets/industry/i-page2-2.png"), step: 'STEP2', title: '执行优化', desc: '采用人工神经元网络模型，详细换热网络模型及非线性优化算法。' },
            { icon: require("../../assets/industry/i-page2-3.png"), step: 'STEP3', title: '分析结果', desc: '分析确认结果，制定操作优化实施策略' },
          ],
          imgList: [require("../../assets/solution/op/s-sl-img-1.png"), require("../../assets/solution/op/s-sl-img-2.png"), require("../../assets/solution/op/s-sl-img-3.png"), require("../../assets/solution/op/s-sl-img-4.png")],
        },
        {
          title: '实验室信息管理',
          desc: '以ISO/IEC17025、CNAS等相关法规和标准要求为准则，集检验流程管理、实验室资源管理、质量数据管理等功能为一体的实验室信息管理平台，实现实验数据的自动化采集，化验流程的信息化管理，为企业的质量控制和生产优化提供及时可靠的数据依据和决策支持，整体提升企业质量管理的效率和精细化水平。',
          desc1: '针对实验室研发设计，并采用科学的管理思想和先进的数据库技术，来实现以实验室为核心的研发型整体的全方位管理。',
          sc: ['石油化工', '金属冶炼', '食药检测', '医学检测'],
          wt: [
            { icon: require("../../assets/industry/i-page2-1.png"), step: 'STEP1', title: '检验流程管理', desc: '样品计划、样品标签、接收指派、留样管理、仪器数据采集、结果录入、结果审核' },
            { icon: require("../../assets/industry/i-page2-2.png"), step: 'STEP2', title: '实验室资源管理', desc: '人员管理、人员考核、仪器管理、材料管理、方法标准管理、环境管理' },
            { icon: require("../../assets/industry/i-page2-3.png"), step: 'STEP3', title: '质量数据管理', desc: '样品查询、样品报告、分析进度跟踪、数据展示分析' },
          ],
          yy: require("../../assets/solution/lab/s-sl-yy.png"),
          banner: require("../../assets/solution/lab/s-sl-img-banner.png"),
          imgList: [require("../../assets/solution/lab/s-sl-img-1.png"), require("../../assets/solution/lab/s-sl-img-2.png"), require("../../assets/solution/lab/s-sl-img-3.png"), require("../../assets/solution/lab/s-sl-img-4.png")],
        },
        {
          title: '安环应急指挥',
          desc: '建立、健全企业安环应急指挥体系和对突发事件预警和应急保障机制，通过安环应急指挥系统的建设，实现事故预测预判和风险有效防控，推动安监工作从被动响应向主动预见转变，从经验决策向基于大数据的科学决策转变，帮助企业有效应对各种突发事件和自然灾害等紧急状况，提高应急处置的解决能力。',
          desc1: '企业安全管理系统,智慧式安全用电管理系统,安全隐患排查系统,多个功能模块,可自定义的数据分析,满足企业内部管理需求,助力企业实现关键人掌控的管理,帮助企业精细化管理..',
          sc: ['物联监控', '舆论管控', '资源调度', '环境监测'],
          wt: [
            { icon: require("../../assets/industry/i-page2-1.png"), step: 'STEP1', title: '安环监控', desc: '整合企业各种资源，将重大危险源在线监测系统、环境在线监测系统、视频监控系统、安全DCS控制系统及等系统整合为一体化管理平台，发挥资源整合作用。' },
            { icon: require("../../assets/industry/i-page2-2.png"), step: 'STEP2', title: '设备预测', desc: '及时预测预警，建立企业安全生产、环保指标数据库，超出监测系统预警上下限值自动报警，指挥中心根据情况实时反馈企业安全环保应急管理部门。' },
            { icon: require("../../assets/industry/i-page2-3.png"), step: 'STEP3', title: '告警管理', desc: '实时在线监测监控，实现主要危险源数据的采集与集中监控，具体采集数据信息，根据数据采集模块采集、存储的数据进行监测、整理、报警、联动，实现企业24小时安全应急管理' },
          ],
          yy: require("../../assets/solution/their/s-sl-yy.png"),
          banner: require("../../assets/solution/their/s-sl-banner.png"),
          imgList: [require("../../assets/solution/their/s-sl-img-1.png"), require("../../assets/solution/their/s-sl-img-2.png"), require("../../assets/solution/their/s-sl-img-3.png"), require("../../assets/solution/their/s-sl-img-4.png")],
        },
        {
          title: '企业运营大数据',
          desc: '依托企业信息化系统及基础设施，集成ERP、PHD、MES、LIMS等信息系统，构建企业ODS集成平台；基于数据模型分析技术，从装置加工、设备运行、质量管控、消防安防、环保监测、生产经营等业务领域进行场景建模，对海量数据层层挖掘，深入分析业务运行的关键指标，从而增强管理决策层业务聚焦，提升企业生产经营决策、调度指挥的智能化水平。',
          desc1: '',
          sc: ['石油、石化工厂', '流程制造业', '热加工行业', '半导体制造'],
          wt: [
            { icon: require("../../assets/industry/i-page2-1.png"), step: 'STEP1', title: '发掘潜在数据', desc: '大数据处理平台的基础上，结合企业实际情况，帮助企业进行主数据建设、数据质量改善等数据的优化治理，在海量的数据中发掘有价值的知识信息。' },
            { icon: require("../../assets/industry/i-page2-2.png"), step: 'STEP2', title: '数据场景建模', desc: '包含数据检验、数据清洗、数据重构，以及数据建模的过程，结合具体的业务场景发现有用的信息，有建设性的结论，辅助决策的制定。' },
            { icon: require("../../assets/industry/i-page2-3.png"), step: 'STEP3', title: '数据智能分析', desc: '通过对历史和现有数据、情景、性能的分析，帮助决策者得到有价值的洞察力，使其能够作出更优的决策。' },
          ],
          yy: require("../../assets/solution/oper/s-sl-yy.png"),
          banner: require("../../assets/solution/oper/s-sl-banner.png"),
          imgList: [require("../../assets/solution/oper/s-sl-img-1.png"), require("../../assets/solution/oper/s-sl-img-2.png"), require("../../assets/solution/oper/s-sl-img-3.png"), require("../../assets/solution/oper/s-sl-img-4.png"),],
        },
        {
          title: '智慧油气田',
          desc: '利用物联网技术，建立覆盖油气举升、油气集输（产量计量）、油气水处理与油气储运等油气生产全过程业务管理系统，应用生产数据自动采集、远程监控、实时诊断、生产预警等功能，实现生产方式的变革＼生产效率的提高、管理水平的提升。其业务范围覆盖。',
          desc1: '',
          sc: ['石油、石化工厂', '燃气', '煤', '海油'],
          wt: [
            { icon: require("../../assets/industry/i-page2-1.png"), step: 'STEP1', title: '物联网远程监控', desc: '通过现场完善的监视、检测设备，实时监测井、间、站场、管网、电力系统设备工况及故障，及时发现异常情况，进行多级预警与报警，并进行及时、有效地处理，保障生产过程的安全性、稳定性。' },
            { icon: require("../../assets/industry/i-page2-2.png"), step: 'STEP2', title: '实时诊断', desc: '对实时采集的生产数据和生产过程产生的业务数据进行汇总、统计、挖掘、分析，为远程生产指挥、生产应急调度、生产工艺流程模拟仿真及优化运行、节能降耗等提供科学的决策依据' },
            { icon: require("../../assets/industry/i-page2-3.png"), step: 'STEP3', title: '安全预警', desc: '及时预测预警，建立企业安全生产、环保指标数据库，超出监测系统预警上下限值自动报警，指挥中心根据情况实时反馈企业安全环保应急管理部门。' },
          ],
          yy: require("../../assets/solution/ga/s-sl-yy.png"),
          banner: require("../../assets/solution/ga/s-sl-banner.png"),
          imgList: [require("../../assets/solution/imoo/s-Imoo-img-1.png"), require("../../assets/solution/imoo/s-Imoo-img-3.png"), require("../../assets/solution/imoo/s-Imoo-img-2.png"), require("../../assets/solution/imoo/s-Imoo-img-4.png")],
        },

      ],

    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.title = localStorage.getItem('CISSLUTIONINFO')
      let { desc, desc1, wt, sc, imgList, banner, yy } = this.list.filter(e => e.title == localStorage.getItem('CISSLUTIONINFO'))[0]
      this.desc = desc
      this.desc1 = desc1
      this.wt = wt
      this.sc = sc
      this.imgList = imgList
      this.banner = banner
      this.yy = yy
    },
    home5Mouseenter(i) {
      this.home5Index = i
    },
    handleBack() {
      this.$router.go(-1)
    },
    handleME(i) {
      this.home6Bol = false
      this.home6Index = i
    },
    handleLE() {
      this.home6Bol = true
      this.home6Index = -1
    },


  },
  watch: {
    // 利用watch方法检测路由变化：
    '$route': function () {
      this.getData()
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 980px) {
  .banner {
    width: 100%;
    height: 450px;
    background: url("../../assets/solution/s-banner.png") no-repeat;
    background-size: cover;
    color: #fff;
    .back {
      padding-top: 100px;
      cursor: pointer;
    }
    .title {
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 86px;
      font-size: 1.825em;
      margin-top: 20px;
    }
    .desc {
      width: 750px;
    }
  }
  .banner-desc {
    width: 550px;
    text-align: center;
    color: #fff;
  }
}
@media only screen and (min-width: 320px) and (max-width: 980px) {
  .banner {
    width: 100%;
    height: 350px;
    background: url("../../assets/solution/s-banner.png") no-repeat;
    background-size: cover;
    color: #fff;
    .back {
      padding-top: 100px;
      cursor: pointer;
      padding-left: 20px;
    }
    .title {
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 86px;
      font-size: 1.825em;
      padding-left: 20px;
      margin-top: 20px;
    }
    .desc {
      width: 320px;
      padding-left: 20px;
    }
  }
  .banner-desc {
    width: 550px;
    text-align: center;
    color: #fff;
  }
}
.page_container {
  background-color: #fff;
  .page-title {
    color: #2f3154;
    line-height: 45px;
    letter-spacing: 2px;
    font-size: 1.825em;
    text-align: center;
    font-weight: 500;
  }
  .sub_title {
    color: #666666;
    line-height: 28px;
    letter-spacing: 1px;
    font-size: 0.825em;
    text-align: center;
    padding: 15px 20px;
    margin-bottom: 25px;
  }
  .page2,
  .page1,
  .page5,
  .page6 {
    padding: 65px 0;
  }
  .p-page2,
  .p-page1 {
    padding: 25px 0;
    .page-title {
      color: #2f3154;
      line-height: 45px;
      letter-spacing: 2px;
      font-size: 1.5em;
      text-align: center;
      font-weight: 500;
    }
    .sub_title {
      color: #666666;
      line-height: 20px;
      letter-spacing: 1px;
      font-size: 0.825em;
      text-align: center;
      padding: 15px 20px;
      margin-bottom: 25px;
    }
  }

  .p-page1 {
    padding: 25px 20px;
    .fun {
      background-color: #fff;
      text-align: center;
      margin-bottom: 30px;
      padding: 9px 0;
      box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.1);
    }
  }

  .page1 {
    .fun {
      background-color: #fff;
      text-align: center;
      margin-bottom: 30px;
      padding: 9px 0;
      box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.1);
    }
  }
  .p-page2 {
    .list-item-pro {
      margin: 20px;
      margin-top: 0;
      padding: 35px;
      box-shadow: 1px 1px 30px rgba(49, 69, 244, 0.1);
      position: relative;
      z-index: 1;
      background-color: #fff;
      position: relative;
      .p2_bg {
        width: 25px;
        position: absolute;
        right: -6px;
        bottom: -6px;
      }
      .item-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .step {
          font-size: 0.625em;
          color: #11223f;
          line-height: 22px;
        }
        .image {
          width: 45px;
          height: 45px;
        }
        .title {
          font-size: 0.825em;
          font-weight: 500;
          margin-top: 15px;
        }
      }
      .item-bottom {
        font-size: 0.625em;
        color: #11223f;
        margin-top: 15px;
      }
    }
  }

  .page2 {
    padding-bottom: 85px;
    .page-container {
      .list-item {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        position: relative;
        .list-item-pro {
          width: 200px;
          padding: 35px;
          box-shadow: 1px 1px 30px rgba(49, 69, 244, 0.1);
          position: relative;
          z-index: 1;
          background-color: #fff;
          position: relative;
          .p2_bg {
            width: 25px;
            position: absolute;
            right: -6px;
            bottom: -6px;
          }
          .item-top {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .step {
              font-size: 0.625em;
              color: #11223f;
              line-height: 22px;
            }
            .image {
              width: 45px;
              height: 45px;
            }
            .title {
              font-size: 0.825em;
              font-weight: 500;
              margin-top: 15px;
            }
          }
          .item-bottom {
            font-size: 0.625em;
            color: #11223f;
            margin-top: 15px;
          }
        }
      }
    }
  }

  .page3 {
    padding-bottom: 85px;
    .page-container {
      position: relative;
      .p3-bg {
        position: absolute;
        top: -50px;
        left: -50px;
        width: 250px;
      }
      .list-item {
        display: flex;
        flex-direction: row;
        padding: 30px;
        .list-item-left {
          margin-right: 30px;
          .image {
            width: 520px;
          }
        }
        .list-item-right {
          width: 520px;
          padding-bottom: 20px;
          .title {
            color: #00233e;
            font-size: 1.25em;
            line-height: 3em;
          }
          .desc {
            color: #666666;
            font-size: 0.825em;
            line-height: 2em;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 7;
            -webkit-box-orient: vertical;
            display: -moz-box;
            -moz-line-clamp: 7;
            -moz-box-orient: vertical;
            word-wrap: break-word;
            word-break: break-all;
            white-space: normal;
          }
        }
      }
    }
  }

  .p-page3 {
    .content {
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 20px;
      .title {
        font-size: 1.25em;
        line-height: 2em;
      }
      .desc {
        font-size: 0.625em;
        color: #909090;
      }
    }
  }

  .page5 {
    .content {
      margin-top: 35px;
      display: flex;
      flex-direction: row;
      .item {
        height: 450px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 25px;
        color: #fff;
      }
    }
  }

  .page6 {
    .p-img {
      width: 94%;
      padding: 3%;
      height: 350px;
      margin-top: 35px;
    }
    .img {
      width: 100%;
      height: 450px;
      margin-top: 35px;
    }
  }

  .p-page6 {
    padding: 35px 20px;
    .page-title {
      margin-bottom: 25px;
    }
  }
}
</style>